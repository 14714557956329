import { VendorCode } from './types'

export const redirectMenuItems = [
  { key: VendorCode.FakeChoi_Evolution, name: '원본 에볼' },
  { key: VendorCode.CX_EVOLUTION, name: 'SWIX 사이다' },
  { key: VendorCode.FakeCX_Evolution, name: 'SWIX 스타' },
  { key: VendorCode.RealGates, name: '리얼게이트' },
  { key: VendorCode.TrippleA_RedPlanet, name: '트리플A 레드플라넷' },
  { key: VendorCode.TrippleA_Damoa, name: '트리플A 다모아' },
  { key: VendorCode.TrippleA_Hero, name: '트리플A 히어로' },
  { key: VendorCode.TrippleA_Natural, name: '트리플A 내추럴' },
  { key: VendorCode.TrippleA_Plus, name: '트리플A 플러스' },
  { key: VendorCode.TrippleA_Star, name: '트리플A 스타' },
  //{ key: VendorCode.HonorLink_KSlot3_Evolution, name: '아너링크 K슬롯3' },
  //{ key: VendorCode.Alpha_Cider_Evolution, name: '알파 사이다' },
  //{ key: VendorCode.FakeUnionGame_Cool_Evolution, name: '유니온 쿨' },
  { key: VendorCode.HonorLink_Evolution, name: '아너링크 에볼' },
  { key: VendorCode.FakeBlue_Evolution, name: '페이크 블루' },
  { key: VendorCode.FakeGreen_Evolution, name: '페이크 그린' },
  { key: VendorCode.FakeLemon_Evolution, name: '페이크 레몬' },
  { key: VendorCode.FakeTomato_Evolution, name: '페이크 토마토' },
  { key: VendorCode.FakeBlueGreen_Evolution, name: '페이크 블루그린' },
  /*{ key: VendorCode.FakeGold_Evolution, name: '페이크 골드' },
  { key: VendorCode.FakeSilver_Evolution, name: '페이크 실버' },
  { key: VendorCode.FakeGrape_Evolution, name: '페이크 포도' },
  { key: VendorCode.FakeApple_Evolution, name: '페이크 사과' },*/
]

export const redirectColorTable: { [key: string]: string } = {
  [VendorCode.HonorLink_Evolution]: 'lightgray',
  [VendorCode.FakeBlue_Evolution]: 'lightskyblue',
  [VendorCode.FakeGreen_Evolution]: 'lightgreen',
  [VendorCode.FakeLemon_Evolution]: 'LemonChiffon',
  [VendorCode.FakeTomato_Evolution]: 'tomato',
  [VendorCode.FakeGold_Evolution]: 'gold',
  [VendorCode.FakeSilver_Evolution]: 'silver',
  [VendorCode.FakeGrape_Evolution]: 'mediumpurple',
  [VendorCode.FakeApple_Evolution]: 'crimson',
  [VendorCode.FakeBlueGreen_Evolution]: 'cyan',
}
